import { gql, useQuery } from "@apollo/client";
import {
  Flex,
  InputText,
  InputDropdown,
  InputFilterable,
  InputCheckbox,
  InputPostalCode,
  InputTextarea,
} from "@heart/components";
import { sortBy, without } from "lodash";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { translationWithRoot } from "@components/T";

import PrimarySubdivisionsQuery from "@graphql/queries/PrimarySubdivisions.graphql";

import { formatDateTimeAsShortDate } from "@lib/dates";

import {
  AGENCY_HUMAN_ADDRESS_TYPES,
  ADDRESS_TYPE_MAILING,
  ADDRESS_TYPE_PHYSICAL_INCARCERATED,
} from "@root/constants";

const { t } = translationWithRoot("heart.components.inputs.input_address");

export const CountriesQuery = gql`
  query Countries {
    countries {
      id
      code
      name
    }
  }
`;

/** ### Usage
 *
 * A component for entering a single address, US or international.
 *
 * It allows a user to:
 *    - Mark an address as primary
 *    - Mark an address as inactive and log notes about why it's inactive
 *    - Specify the type of address (home, work, mailing only)
 *    - Indicate that a home or work address can also be used for mailing
 *
 * The component is intended to be used within MultiInputTemplate or
 * some other pattern that allows for multiple address inputs to be entered.
 *
 * ### Cypress
 *
 * Optionally takes in an object to indicate the address type:
 * `cy.fillInputAddress({ type: "Home" })`
 *
 * If type is "Incarcerated", the facility name and booking number will also
 * be filled out: `cy.fillInputAddress({ type: "Incarcerated" })`
 */

export const InputAddress = ({
  value = {
    type: [],
    addressLine1: "",
    countryCode: "US",
    postalCode: "",
    primary: false,
    inactive: false,
  },
  onChange,
  allowedAddressTypes = AGENCY_HUMAN_ADDRESS_TYPES,
  hideInactiveCheckbox = false,
  disablePrimaryCheckbox = false,
}) => {
  const [address, setAddress] = useState(value);
  useEffect(() => setAddress(value), [value]);

  const containsMailing = address.type.includes(ADDRESS_TYPE_MAILING);
  const isMailingOnly = address.type.length === 1 && containsMailing;
  const updateFields = updates => {
    setAddress({ ...address, ...updates });
    if (onChange) {
      onChange({ ...address, ...updates });
    }
  };

  const { data: countriesData } = useQuery(CountriesQuery);

  const { data: primarySubdivisionsData } = useQuery(PrimarySubdivisionsQuery, {
    variables: { countryCode: address.countryCode },
    // if address.countryCode is not set (""), we skip running this query
    skip: !address.countryCode,
  });

  // We only store the country code but need to have a label/value pair
  // to pass in as value to InputFilterable used for the country selection.
  // This method gets the appropriate label.
  const getCountryValue = () => {
    if (countriesData) {
      const countryMatch = countriesData.countries.find(
        ({ code }) => code === address.countryCode
      );
      if (countryMatch) {
        return {
          label: countryMatch.name,
          value: countryMatch.code,
        };
      }
    }
    return undefined;
  };

  return (
    <Flex column data-heart-component="InputAddress">
      <InputDropdown
        label={t("type")}
        values={allowedAddressTypes.map(type => [t(type), type])}
        required
        // When there are more than one type, sort reverse alphabetically
        // so that physical_* comes before mailing. Display the physical_* option.
        // Mailing wil be displayed by checkbox below.
        value={
          address.type.length > 1
            ? sortBy(address.type).reverse()[0]
            : address.type[0]
        }
        onChange={val =>
          // Clear out customFields if type changes
          updateFields({ customFields: {}, type: [val] })
        }
        disabled={allowedAddressTypes.length === 1}
      />
      <InputCheckbox
        label={t("primary")}
        value={address.primary}
        onChange={val => updateFields({ primary: val })}
        disabled={disablePrimaryCheckbox}
      />
      {/* This checkbox is only relevant when the type is physical */}
      <If condition={!isMailingOnly}>
        <InputCheckbox
          label={t("use_as_mailing")}
          value={containsMailing}
          onChange={val => {
            let newType = address.type;
            // if checked, add ADDRESS_TYPE_MAILING to the array
            if (val === true) {
              newType = address.type.concat(ADDRESS_TYPE_MAILING);
              // if un-checked, remove ADDRESS_TYPE_MAILING from the array
            } else {
              newType = without(address.type, ADDRESS_TYPE_MAILING);
            }
            updateFields({ type: newType });
          }}
        />
      </If>
      {/* These inputs are only relevant when the type is incarcerated */}
      <If condition={address.type.includes(ADDRESS_TYPE_PHYSICAL_INCARCERATED)}>
        <InputText
          label={t("booking_number")}
          type="text"
          required
          value={address.customFields?.booking_number}
          onChange={val =>
            updateFields({
              customFields: { ...address.customFields, booking_number: val },
            })
          }
        />
        <InputText
          label={t("facility_name")}
          type="text"
          required
          value={address.customFields?.facility_name}
          onChange={val =>
            updateFields({
              customFields: { ...address.customFields, facility_name: val },
            })
          }
        />
      </If>
      <InputText
        label={t("address_line_1")}
        type="text"
        required
        value={address.addressLine1}
        onChange={val => updateFields({ addressLine1: val })}
      />
      <InputText
        label={t("address_line_2")}
        type="text"
        value={address.addressLine2}
        onChange={val => updateFields({ addressLine2: val })}
      />
      <InputText
        label={t("city")}
        type="text"
        value={address.city}
        onChange={val => updateFields({ city: val })}
      />
      <InputFilterable
        label={t("country")}
        required
        values={
          countriesData
            ? countriesData.countries.map(({ code, name }) => ({
                label: name,
                value: code,
              }))
            : []
        }
        value={getCountryValue()}
        onChange={({ value: val }) => {
          // clear primarySubdivision if countryCode is changed
          updateFields({ countryCode: val, primarySubdivision: "" });
        }}
      />
      <InputFilterable
        label={t("primary_subdivision")}
        values={
          primarySubdivisionsData
            ? primarySubdivisionsData.primarySubdivisions.map(({ name }) => ({
                label: name,
                value: name,
              }))
            : []
        }
        value={{
          label: address.primarySubdivision,
          value: address.primarySubdivision,
        }}
        onChange={({ value: val }) => updateFields({ primarySubdivision: val })}
      />
      <InputPostalCode
        label={t("postal_code")}
        value={address.postalCode}
        onChange={val => updateFields({ postalCode: val })}
        countryCode={address.countryCode}
        required
      />
      <If condition={!hideInactiveCheckbox}>
        <InputCheckbox
          label={t("inactive")}
          value={address.inactive}
          description={
            address.inactiveUpdatedAt && address.inactive
              ? t("inactive_description", {
                  inactive_updated_at: formatDateTimeAsShortDate(
                    address.inactiveUpdatedAt
                  ),
                })
              : ""
          }
          onChange={val => updateFields({ inactive: val })}
          error={
            address.inactive && address.primary
              ? t("inactive_primary_error")
              : null
          }
        />
      </If>
      <If condition={address.inactive}>
        <InputTextarea
          label={t("inactive_details")}
          value={address.inactiveDetails}
          onChange={val => updateFields({ inactiveDetails: val })}
          placeholder={t("inactive_details_description")}
        />
      </If>
    </Flex>
  );
};

InputAddress.propTypes = {
  /** The initial (or current) value for the address, as an object */
  value: PropTypes.shape({
    /* id will be populated if value is a persisted address */
    id: PropTypes.string,
    type: PropTypes.array.isRequired,
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    primarySubdivision: PropTypes.string,
    countryCode: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    primary: PropTypes.bool.isRequired,
    inactive: PropTypes.bool.isRequired,
    inactiveDetails: PropTypes.string,
    inactiveUpdatedAt: PropTypes.string,
    customFields: PropTypes.object,
  }).isRequired,
  /** The `onChange` function is invoked with the entire address object */
  onChange: PropTypes.func,
  /** Use this to restrict the address types the user can select.
   *  If only one is allowed, make sure the value passed in has that
   * type pre-set in the type array, because the dropdown will be disabled.
   */
  allowedAddressTypes: PropTypes.array,
  /** Hides the checkbox allowing this address to be listed as inactive. */
  hideInactiveCheckbox: PropTypes.bool,
  /** Disables the checkbox for marking this address as primary so it can't be
   * changed. The checkbox is always visible, so be sure to set the value for "primary"
   * in the value to either true or false as appropriate for your use case before
   * this component is rendered.
   */
  disablePrimaryCheckbox: PropTypes.bool,
};

export default InputAddress;
