import { useQuery } from "@apollo/client";
import {
  Icons,
  Flex,
  LoadingOverlay,
  NodeDiagram,
  Toggle,
} from "@heart/components";
import PropTypes from "prop-types";
import React, { useState } from "react";

import AgencyHumanRelationships from "@graphql/queries/AgencyHumanRelationships.graphql";

import ViewRelationshipModal from "../relationships/ViewRelationshipModal";
import ChildNode from "./ChildNode";
import ConnectionNode from "./ConnectionNode";

const customNodes = { personNode: ConnectionNode, childNode: ChildNode };
const position = { x: 0, y: 0 };

const partitionMap = {
  grandmother: 4,
  grandfather: 4,
  grandparent: 4,
  aunt: 3,
  uncle: 3,
  mother: 3,
  father: 3,
  parent: 3,
  married: 2,
  divorced: 2,
  engaged: 2,
  dating: 2,
  cohabitating: 2,
  exes: 2,
  partner: 2,
  cousin: 2,
  sibling: 2,
  niece: 1,
  nephew: 1,
  daughter: 1,
  son: 1,
  child: 1,
  fictive_kin: 0,
};

/** A first iteration of a genogram, using only generational layers and
 * one degree relationships off of the child
 *
 * This is a super super super rough draft - eventually we'll need to change
 * a lot of the logic to implement the one degree relationships that will help
 * shape this into a better genogram shape. I'm not worrying too much about
 * the cleanliness of the code here because I'm going to be making so many changes
 * to it!
 */
const Genogram = ({ childAgencyHumanId }) => {
  const [reactFlowInstance, setReactFlowInstance] = useState();
  const [relationshipToView, setRelationshipToView] = useState();
  const { data: relationshipData = { agencyHumanRelationships: [] }, loading } =
    useQuery(AgencyHumanRelationships, {
      variables: { agencyHumanId: childAgencyHumanId },
      /** Once the query is complete, we want to center the view of the genogram */
      onCompleted: () => setTimeout(reactFlowInstance?.fitView, 10),
    });

  let childNode;
  const nodes = [
    /** Adding a hidden node that connects to all the nodes of the graph.
     *
     * This is necessary as nodes without any edges attached do not take their
     * partition into account, which means we don't get generational lines
     * among folks that aren't connected via 1 degree relationships
     */
    {
      id: "hiddenConnectorNode",
      hidden: true,
      position,
      layoutOptions: { "partitioning.partition": 10000 },
    },
  ];
  const dynamicEdges = [];
  const staticEdges = [];

  const firstRelationship = relationshipData.agencyHumanRelationships[0];
  const childAgencyHuman =
    firstRelationship?.sourceAgencyHuman?.id === childAgencyHumanId
      ? firstRelationship?.sourceAgencyHuman
      : firstRelationship?.destinationAgencyHuman;
  if (firstRelationship) {
    childNode = {
      id: "child",
      type: "childNode",
      data: childAgencyHuman,
      position,
      /** The child sits at layer 2, above children/nieces/nephews of the child (layer 1)
       * and fictive kin of the child (layer 0)
       */
      layoutOptions: { "partitioning.partition": 2 },
    };
    nodes.push(childNode);
  }

  relationshipData.agencyHumanRelationships.forEach(relationship => {
    const node = {
      id: relationship.id,
      type: "personNode",
      data: {
        keystoneAgencyHumanId: childAgencyHumanId,
        relationship,
        setRelationshipToView,
      },
      position,
      layoutOptions: {
        "partitioning.partition":
          partitionMap[relationship.kinshipRelationship],
      },
    };
    if (Object.keys(partitionMap).includes(relationship.kinshipRelationship)) {
      /** Adding an edge between all of the nodes that are included in the genogram (ignoring any
       * nodes that have kinship relationships that don't result in being included) and a hidden
       * node that connects to them all. This is necessary for the partitions to have an impact, as
       * nodes without any edges attached do not take their partition into account. These edges
       * will not be visible in the final genogram.
      ) */
      dynamicEdges.push({
        id: `${node.id}-connector`,
        source: "hiddenConnectorNode",
        target: node.id,
        type: "straight",
      });
      nodes.push(node);
    }
    if (relationship.kinshipRelationship === "fictive_kin") {
      dynamicEdges.push({
        id: `${childNode.id}-${node.id}`,
        target: childNode.id,
        source: node.id,
        sourceHandle: "fictive-kin",
        targetHandle: "fictive-kin",
        type: "straight",
        label: relationship.fictiveKinDescription,
      });
    } else if (
      ["daughter", "son", "child"].includes(relationship.kinshipRelationship)
    ) {
      dynamicEdges.push({
        id: `${childNode.id}-${node.id}`,
        source: node.id,
        target: childNode.id,
        sourceHandle: "parent",
        targetHandle: "child",
        type: "step",
      });
    } else if (relationship.kinshipRelationship === "sibling")
      dynamicEdges.push({
        id: `${childNode.id}-${node.id}`,
        source: childNode.id,
        target: node.id,
        sourceHandle: "sibling",
        targetHandle: "sibling",
        type: "step",
      });
    else if (
      [
        "married",
        "divorced",
        "engaged",
        "dating",
        "cohabitating",
        "exes",
        "partner",
      ].includes(relationship.kinshipRelationship)
    )
      dynamicEdges.push({
        id: `${childNode.id}-${node.id}`,
        source: childNode.id,
        target: node.id,
        sourceHandle: "partner",
        targetHandle: "partner",
        type: "step",
      });
    else if (
      ["mother", "father", "parent"].includes(relationship.kinshipRelationship)
    )
      dynamicEdges.push({
        id: `${childNode.id}-${node.id}`,
        source: childNode.id,
        target: node.id,
        sourceHandle: "parent",
        targetHandle: "child",
        type: "step",
      });
  });

  return (
    <LoadingOverlay active={loading}>
      <ViewRelationshipModal
        relationship={relationshipToView}
        keystoneAgencyHumanId={childAgencyHumanId}
        setRelationshipToView={setRelationshipToView}
      />
      <Flex justify="end">
        <Toggle
          LeftIcon={Icons.List}
          RightIcon={Icons.Sitemap}
          leftDescription="View Table"
          rightDescription="View Genogram"
          leftView="table"
          rightView="genogram"
        />
      </Flex>
      <NodeDiagram
        nodes={nodes}
        dynamicEdges={dynamicEdges}
        staticEdges={staticEdges}
        graphDirection="UP"
        nodeHeight={200}
        nodeWidth={300}
        customNodes={customNodes}
        onInit={instance => setReactFlowInstance(instance)}
      />
    </LoadingOverlay>
  );
};
Genogram.propTypes = {
  childAgencyHumanId: PropTypes.string.isRequired,
};

export default Genogram;
