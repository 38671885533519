/** Conversion utilities for translating back and forth between
 * the way we're storing data in our DB and the way we display
 * inputs and information in the UI
 *
 * Our UI takes a more step by step approach to asking users for
 * information, whereas our DB only stores the information it
 * needs to extract this information for our UI.
 */
import I18n from "i18n-js";
import { omit, isNil } from "lodash";

import { GENDERS_OPEN_TO_OPTIONS } from "@root/constants";

export const addressesForUI = ({ addresses }) => ({
  addresses: addresses.map(address => ({
    id: address.id,
    address: address,
  })),
});

export const addressesForDB = ({ addresses = [], agencyHuman, agencyId }) => ({
  addresses: addresses.map(({ address }) => ({
    addresseeId: agencyHuman.id || null,
    addresseeType: "AgencyHuman",
    agencyId: agencyId,
    customFields: {},
    // CountryName is just used when viewing addresses for an AgencyHuman and
    // it doesn't get updated when the country is changed when editing, so
    // remove it from address to be persisted.
    ...omit(address, "countryName"),
  })),
});

/** A shim to translate between the human-readable string version of our
 * gender options and the slug version as we are storing our gender values
 * inconsistently
 * TODO: ENG-11316
 */
const genderMap = GENDERS_OPEN_TO_OPTIONS.reduce(
  (genderTranslations, gender) => ({
    ...genderTranslations,
    [I18n.t(`activerecord.enums.common.gender.${gender}`, { locale: "en" })]:
      gender,
  }),
  {}
);
export const genderForUI = ({ gender } = {}) => ({
  gender: GENDERS_OPEN_TO_OPTIONS.includes(gender) ? gender : genderMap[gender],
});

export const icwaFieldsForUI = ({
  protectedTribeMember,
  protectedTribeNames,
  protectedTribeIdentification,
} = {}) => ({
  askedAboutProtectedTribe: !isNil(protectedTribeMember),
  tribes: {
    isProtectedTribeMember: protectedTribeMember,
    tribeNames: (protectedTribeNames || []).map(name => ({
      label: name,
      value: name,
    })),
    tribeIdentification: protectedTribeIdentification,
  },
});

export const icwaFieldsForDB = ({ tribes = {} }) => ({
  protectedTribeMember: tribes.isProtectedTribeMember,
  protectedTribeNames: (tribes.tribeNames || []).map(({ value }) => value),
  protectedTribeIdentification: tribes.tribeIdentification,
});

export const phoneNumbersForUI = ({ phoneNumbers = [] } = {}) => ({
  phoneNumbers: phoneNumbers.map(({ active, ...rest }) => ({
    inactive: !active,
    ...rest,
  })),
});

export const phoneNumbersForDB = ({ agencyHuman, phoneNumbers = [] }) => ({
  phoneNumbers: phoneNumbers.map(({ inactive, ...rest }) => ({
    personType: "AgencyHuman",
    personId: agencyHuman.id,
    active: !inactive,
    ...rest,
  })),
});

export const socialMediaLinksForUI = ({ socialMediaLinks = [] } = {}) => ({
  socialMediaLinks: socialMediaLinks.map(link => ({ socialMediaLink: link })),
});

export const socialMediaLinksForDB = ({ socialMediaLinks = [] }) => ({
  socialMediaLinks: socialMediaLinks.map(
    ({ socialMediaLink }) => socialMediaLink
  ),
});
