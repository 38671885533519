import { useMutation } from "@apollo/client";
import { Wizard, WizardPage } from "@heart/components";
import useCurrentPageSearchParam from "@heart/components/layout/page_layout/useCurrentPageSearchParam";
import { useMountEffect } from "@react-hookz/web";
import I18n from "i18n-js";
import { sum } from "lodash";
import { Fragment, useCallback, useState } from "react";
import { useBeforeunload } from "react-beforeunload";

import { translationWithRoot } from "@components/T";

import CreateCaseNoteTranscriptionProcess from "@graphql/mutations/CreateCaseNoteTranscriptionProcess.graphql";
import CreateFormTranscriptionProcess from "@graphql/mutations/CreateFormTranscriptionProcess.graphql";

import { prepareShrineFiles } from "@lib/graphqlHelpers";

import ProvideRecordingPage from "./ProvideRecordingPage";
import SelectCaregiverPage from "./SelectCaregiverPage";
import SelectTranscriptionTypePage from "./SelectTranscriptionTypePage";
import { FORM } from "./constants";

const { t } = translationWithRoot(
  "transcription.create_transcription_process_form"
);

/**
 * Form for creating a new transcription process for a Binti form or case note.
 */
const CreateTranscriptionProcessForm = ({ redirectionPath }) => {
  const [caregiver, setCaregiver] = useState(null);
  const [recordingFiles, setRecordingFiles] = useState(null);
  const [transcriptionSource, setTranscriptionSource] = useState(null);
  const [transcriptionType, setTranscriptionType] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createCaseNoteTranscriptionProcess] = useMutation(
    CreateCaseNoteTranscriptionProcess
  );
  const [createFormTranscriptionProcess] = useMutation(
    CreateFormTranscriptionProcess
  );
  const { currentPage, navigateToPage } = useCurrentPageSearchParam({
    defaultPage: 0,
  });

  // we don't persist anything until the final page, so treat reloads
  // as restarting the process
  useMountEffect(() => {
    if (currentPage !== 0) {
      navigateToPage({ page: 0 });
    }
  });

  // if the user tries to leave/reload the page after starting the process,
  // confirm that they want to leave
  useBeforeunload(
    useCallback(
      () => (caregiver || recordingFiles) && !isSubmitting,
      [caregiver, recordingFiles, isSubmitting]
    )
  );

  const onSubmit = useCallback(async () => {
    if (!caregiver || !recordingFiles) return undefined;

    const preparedRecordingFiles = await prepareShrineFiles(recordingFiles);

    setIsSubmitting(true);

    if (transcriptionType === FORM) {
      await createFormTranscriptionProcess({
        variables: {
          applicationId: caregiver.applicationId,
          recordingFile: preparedRecordingFiles[0],
        },
      });
    } else {
      await createCaseNoteTranscriptionProcess({
        variables: {
          userAgencyProfileId: caregiver.userAgencyProfileId,
          recordingFile: preparedRecordingFiles[0],
        },
      });
    }

    window.location = redirectionPath;

    // return a never ending promise so the submit button spins until
    // the page reloads
    return new Promise(() => {});
  }, [
    caregiver,
    createCaseNoteTranscriptionProcess,
    createFormTranscriptionProcess,
    recordingFiles,
    redirectionPath,
    transcriptionType,
  ]);

  return (
    <Fragment>
      <Wizard
        title={t("title")}
        pages={[
          <WizardPage
            key="select_caregiver"
            pageTitle={t("select_caregiver_page_title")}
            progress={sum([caregiver ? 50 : 0, transcriptionSource ? 50 : 0])}
            actionsProps={{
              cancelHref: "/",
              primaryAction: () => {},
              primaryText: I18n.t("common.next"),
              primaryDisabled: !caregiver || !transcriptionSource,
            }}
          >
            <SelectCaregiverPage
              transcriptionSource={transcriptionSource}
              setTranscriptionSource={setTranscriptionSource}
              caregiver={caregiver}
              setCaregiver={setCaregiver}
            />
          </WizardPage>,
          <WizardPage
            key="provide_recording"
            pageTitle={t("recording_page_title")}
            progress={recordingFiles ? 100 : 0}
            actionsProps={{
              cancelHref: "/",
              primaryText: I18n.t("common.next"),
              primaryAction: () => {},
              primaryDisabled: !recordingFiles,
              secondaryText: I18n.t("common.back"),
              secondaryAction: () => {},
            }}
          >
            <ProvideRecordingPage
              transcriptionSource={transcriptionSource}
              setRecordingFiles={setRecordingFiles}
              recordingFiles={recordingFiles}
              confirm={confirm}
            />
          </WizardPage>,
          <WizardPage
            key="process_transcription"
            pageTitle={t("process_transcription_page_title")}
            progress={0}
            actionsProps={{
              cancelHref: "/",
              primaryAction: onSubmit,
              secondaryText: I18n.t("common.back"),
              secondaryAction: () => {},
            }}
          >
            <SelectTranscriptionTypePage
              setTranscriptionType={setTranscriptionType}
            />
          </WizardPage>,
        ]}
      />
    </Fragment>
  );
};

export default CreateTranscriptionProcessForm;
