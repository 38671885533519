import { Flex } from "@heart/components";
import classNames from "classnames";
import PropTypes from "prop-types";

import { setBase64SearchParams } from "@lib/base64SearchParams";
import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";

import styles from "./Toggle.module.scss";

/** A toggle component for switching between two different views
 *
 * This component will change the base64 encoded `view` param in the URL, which can
 * then be used by other components to determine what to render
 *
 * Note: this is **NOT** intended to be used as a form toggle element, but rather
 * as a way to toggle between two different views for a set of data. For example,
 * toggling between a table and a chart
 */
const Toggle = ({
  LeftIcon,
  RightIcon,
  leftDescription,
  rightDescription,
  leftView,
  rightView,
  "data-testid": testId,
}) => {
  const view = useBase64SearchParam("view");

  return (
    <Flex data-heart-component="button" data-testid={testId} gap="0">
      <LeftIcon
        description={leftDescription}
        onClick={() =>
          setBase64SearchParams([{ attribute: "view", value: leftView }])
        }
        className={classNames(
          styles.flattenRight,
          styles.toggleBase,
          view !== rightView ? styles.active : styles.inactive
        )}
      />
      <RightIcon
        description={rightDescription}
        onClick={() =>
          setBase64SearchParams([{ attribute: "view", value: rightView }])
        }
        className={classNames(
          styles.flattenLeft,
          styles.toggleBase,
          view === rightView ? styles.active : styles.inactive
        )}
      />
    </Flex>
  );
};
Toggle.propTypes = {
  /** One of the components from `import { Icons } from "@heart/components"`.
   * The icon to display on the left side of the toggle
   */
  LeftIcon: PropTypes.object,
  /** One of the components from `import { Icons } from "@heart/components"`.
   * The icon to display on the right side of the toggle
   */
  RightIcon: PropTypes.object,
  /** Used to indicate to screen readers what the purpose of the left button is,
   * and puts useful hover text on the button.  A combination of HTML
   * `title` and `aria-label` attributes - for further reading:
   *   * https://silktide.com/blog/i-thought-title-text-improved-accessibility-i-was-wrong/
   *   * https://dev.opera.com/articles/ux-accessibility-aria-label/#accessible-name-calculation
   */
  leftDescription: PropTypes.string.isRequired,
  /** Used to indicate to screen readers what the purpose of the right button is,
   * and puts useful hover text on the button.
   */
  rightDescription: PropTypes.string.isRequired,
  /** What the base64 encoded `view` param should be set to when the left button
   * is clicked. This param can then trigger other behavior in the components it is
   * intended to control
   */
  leftView: PropTypes.string.isRequired,
  /** What the base64 encoded `view` param should be set to when the right button
   * is clicked. This param can then trigger other behavior in the components it is
   * intended to control
   */
  rightView: PropTypes.string.isRequired,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};

export default Toggle;
