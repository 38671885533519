import PropTypes from "prop-types";
import { Fragment } from "react";
import { Handle, Position } from "reactflow";

const positionMap = {
  top: Position.Top,
  bottom: Position.Bottom,
  left: Position.Left,
  right: Position.Right,
};

/** A utility component that will render Handles for custom nodes
 * being used by the NodeDiagram. A Handle can be on any side of
 * the node, and by default will be usable as either a source or
 * a target by an edge
 *
 * This component will also remove ReactFlow's Handle styling
 *
 * To use a Handle, give the edge a `sourceHandle={id}` or a
 * `targetHandle={id}` and it will connect the edge to the
 * Handle as appropriate
 */
const UnstyledHandle = ({
  id,
  position,
  useAsSource = true,
  useAsTarget = true,
}) => {
  const types = [];
  if (useAsSource) types.push("source");
  if (useAsTarget) types.push("target");

  return (
    <Fragment>
      {types.map(type => (
        <Handle
          key={`${id}-${type}`}
          id={id}
          type={type}
          isConnectable={false}
          position={positionMap[position]}
          style={{ background: "none", border: "none" }}
        />
      ))}
    </Fragment>
  );
};
UnstyledHandle.propTypes = {
  id: PropTypes.string.isRequired,
  /** What position on the node should the handle be situated in */
  position: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  /** Add a handle for the source of edges, defaults to true */
  useAsSource: PropTypes.bool,
  /** Add a handle for the target of edges, defaults to true */
  useAsTarget: PropTypes.bool,
};

export default UnstyledHandle;
