import { Link, Surface, Upload } from "@heart/components";
import useConfirmModal from "@heart/components/modal/useConfirmModal";
import PropTypes from "prop-types";
import { Fragment, useMemo } from "react";

import { translationWithRoot } from "@components/T";

import TranscriptionInput from "./TranscriptionInput";
import { MICROPHONE, RECORDING } from "./constants";

const { t, T } = translationWithRoot("transcription.provide_recording_page");

/**
 * Second page of the wizard for creating a new transcription process
 * where the user provides the recording to be transcribed either by
 * recording it with their microphone or uploading a file.
 */
const ProvideRecordingPage = ({
  transcriptionSource,
  setRecordingFiles,
  recordingFiles,
}) => {
  const recordingFileUrl = useMemo(
    () => recordingFiles?.[0] && URL.createObjectURL(recordingFiles[0]),
    [recordingFiles]
  );
  const { ConfirmModal, confirm } = useConfirmModal();

  return (
    <Fragment>
      <ConfirmModal title={t("confirm_rerecord_title")}>
        <T t="confirm_rerecord_body" />
      </ConfirmModal>

      <Choose>
        <When condition={transcriptionSource === MICROPHONE}>
          <Surface
            title={t("record_audio")}
            subtitle={t("recording_instructions")}
          >
            <TranscriptionInput
              onNewTranscription={transcriptionFile => {
                setRecordingFiles([transcriptionFile]);
              }}
              isRecordingAllowed={recordingFiles ? confirm : undefined}
            />
          </Surface>
        </When>
        <When condition={transcriptionSource === RECORDING}>
          <Surface
            title={t("upload_audio")}
            subtitle={t("upload_instructions")}
          >
            <Upload onUpload={setRecordingFiles} multiple={false} audioOnly />
          </Surface>
        </When>
      </Choose>
      <If condition={recordingFiles}>
        <Surface title={t("recordings")}>
          <If condition={transcriptionSource === RECORDING}>
            <Link href={recordingFileUrl} target="_blank">
              {recordingFiles[0].name}
            </Link>
          </If>
          {/*
            The user provided this audio, so presumably they know what
            it contains. regardless we'll transcribe it for them in
            due time...
          */}
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio controls src={recordingFileUrl} />
        </Surface>
      </If>
    </Fragment>
  );
};

ProvideRecordingPage.propTypes = {
  transcriptionSource: PropTypes.oneOf([MICROPHONE, RECORDING]),
  confirm: PropTypes.func.isRequired,
  setRecordingFiles: PropTypes.func.isRequired,
  recordingFiles: PropTypes.array,
};

export default ProvideRecordingPage;
