import { Flex } from "@heart/components";
import BintiAssistIcon from "@heart/components/binti_assist/BintiAssistIcon";
import classNames from "classnames";
import { PropTypes } from "prop-types";

import ProgressArc from "@components/ProgressArc";

import styles from "./SidebarSectionTitle.module.scss";

/**
 * A title of a form section in the sidebar of the questionnaire_responses#show page.
 * Includes a progress arc and the title of the section.
 */
const SidebarSectionTitle = ({ progress, title, active, reviewProgress }) => (
  <Flex
    align="start"
    gap="100"
    className={classNames([styles.container, active ? styles.active : null])}
  >
    <ProgressArc
      progress={progress}
      secondaryProgress={reviewProgress}
      secondaryProgressTitle={I18n.t(
        "javascript.components.questionnaires.sidebar_section_title.unreviewed_progress",
        { percent: reviewProgress }
      )}
    />
    {title}
    <If condition={reviewProgress}>
      <BintiAssistIcon
        alt={I18n.t(
          "javascript.components.questionnaires.sidebar_section_title.review_required"
        )}
      />
    </If>
  </Flex>
);

SidebarSectionTitle.propTypes = {
  progress: PropTypes.number,
  title: PropTypes.string,
  active: PropTypes.bool,
  reviewProgress: PropTypes.number,
};

export default SidebarSectionTitle;
