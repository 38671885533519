import { useQuery } from "@apollo/client";

import FeatureFlag from "@graphql/queries/FeatureFlag.graphql";

const useFeatureFlag = flag => {
  const { data: { featureFlag } = {}, loading } = useQuery(FeatureFlag, {
    variables: { flag },
  });

  return { flag: featureFlag, loading };
};

export default useFeatureFlag;
