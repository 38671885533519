import { BintiAssistIcon } from "@heart/components";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";

import DocumentDateSignedInput from "./DocumentDateSignedInput";
import DocumentLinks from "./DocumentLinks";
import DocumentStatus from "./DocumentStatus";
import DocumentTableDataCell from "./DocumentTableDataCell";
import DocumentUpload from "./DocumentUpload";
import MarkNAButton from "./MarkNAButton";
import MarkReceivedButton from "./MarkReceivedButton";

/**
 * Row on the legacy documents page that represents an individual document.
 */
class DocumentRow extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const {
      doc,
      withLinksToDelete,
      withLinksToUpload,
      canManuallyMarkDocumentsComplete,
      backgroundLoading,
    } = this.props;

    const { hover } = this.state;

    const rowClassName = classnames({
      "sub-document": doc.hasParentDocument,
      "parent-document": doc.hasSubDocuments,
      "background-loading": backgroundLoading,
      "upload-hovering": hover,
    });

    const passdownProps = { doc, backgroundLoading };

    return (
      <tr className={rowClassName} onClick={this.onClick}>
        <td
          className={classnames("col", "large-row", {
            "sub-document-title": doc.hasParentDocument,
          })}
        >
          <DocumentTableDataCell header="Title">
            {doc.hasParentDocument ? <span>- {doc.title}</span> : doc.title}
            <If condition={doc.isReviewRequired}>
              <BintiAssistIcon
                alt={I18n.t(
                  "javascript.components.questionnaires.sidebar_section_title.review_required"
                )}
              />
            </If>
            {doc.needsDateSigned && (
              <em>
                {" "}
                (This document requires a signature date before you can upload
                it. Please enter it in the field to the right.)
              </em>
            )}
            {Binti.developmentMode && (
              <div style={{ fontSize: "0.8em", fontStyle: "italic" }}>
                {doc.prettyId}
              </div>
            )}
          </DocumentTableDataCell>
        </td>
        <td className="col min-width numeric-column status-column">
          <DocumentStatus {...passdownProps} />
        </td>
        <td className="col col-document">
          <DocumentLinks
            {...passdownProps}
            withLinksToDelete={withLinksToDelete}
          />
        </td>
        {withLinksToUpload && (
          <td className="col na-column hide-title">
            {!doc.needsDateSigned && <MarkNAButton {...passdownProps} />}
          </td>
        )}
        {canManuallyMarkDocumentsComplete && (
          <td className="col received-column hide-title">
            {!doc.needsDateSigned && <MarkReceivedButton {...passdownProps} />}
          </td>
        )}
        {withLinksToUpload && (
          <td className="col upload-column hide-title">
            {doc.needsDateSigned && (
              <DocumentDateSignedInput {...passdownProps} />
            )}
            {!doc.needsDateSigned && <DocumentUpload {...passdownProps} />}
          </td>
        )}
      </tr>
    );
  }
}

DocumentRow.propTypes = {
  client: PropTypes.object,
  doc: PropTypes.object,
  withLinksToDelete: PropTypes.bool,
  withLinksToUpload: PropTypes.bool,
  canManuallyMarkDocumentsComplete: PropTypes.bool,
  backgroundLoading: PropTypes.bool,
};

export default DocumentRow;
